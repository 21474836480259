import { extendTheme, theme as chakraTheme } from '@chakra-ui/react';

const darkBlue = '#164194';
const midBlue = '#00a6e2';
const lightBlue = '#a1daf8';
const charcoal = '#575756';
const orange = '#f7a823';

export const theme = {
  fonts: {
    heading: ' Bree Serif, Tahoma, Verdana, Segoe, sans-serif',
    body: 'Lato, Tahoma, Verdana, Segoe, sans-serif',
    mono:
      'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;',
  },
  // colors created from https://themera.vercel.app/
  colors: {
    tt: {
      darkBlue,
      midBlue,
      lightBlue,
      charcoal,
      orange,
    },
    orange: {
      '50': '#FEF5E6',
      '100': '#FCE4BA',
      '200': '#FBD28E',
      '300': '#F9C162',
      '400': '#F8AF35',
      '500': '#F69E09',
      '600': '#C57E07',
      '700': '#945F05',
      '800': '#623F04',
      '900': '#312002',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DCDCDB',
      '200': '#C5C5C4',
      '300': '#AEAEAD',
      '400': '#979796',
      '500': '#80807F',
      '600': '#676765',
      '700': '#4D4D4C',
      '800': '#333333',
      '900': '#1A1A19',
    },
    cyan: {
      '50': '#E5F8FF',
      '100': '#B8ECFF',
      '200': '#8AE0FF',
      '300': '#5CD4FF',
      '400': '#2EC7FF',
      '500': '#00BBFF',
      '600': '#0096CC',
      '700': '#007099',
      '800': '#004B66',
      '900': '#002533',
    },
    teal: {
      '50': '#E7F6FD',
      '100': '#BDE5FA',
      '200': '#92D4F7',
      '300': '#67C3F4',
      '400': '#3CB2F1',
      '500': '#12A2ED',
      '600': '#0E81BE',
      '700': '#0B618E',
      '800': '#07415F',
      '900': '#04202F',
    },
    blue: {
      '50': '#E9EFFC',
      '100': '#C1D3F6',
      '200': '#99B7F0',
      '300': '#719AEA',
      '400': '#497EE4',
      '500': '#2161DE',
      '600': '#1A4EB2',
      '700': '#143A85',
      '800': '#0D2759',
      '900': '#07132C',
    },
  },
  components: {
    Link: {
      baseStyle: {
        ...chakraTheme.components.Link,
        textDecoration: 'underline',
      },
    },
    Button: {
      ...chakraTheme.components.Button,
      baseStyle: {
        ...chakraTheme.components.Button.baseStyle,
        textTransform: 'lowercase',
      },
    },
  },
  shadows: {
    ...chakraTheme.shadows,
    outline: '0 0 0 3px #a1daf8',
  },
  styles: {
    global: (props) => ({
      ...chakraTheme.styles.global(props),
      '.gatsby-image-wrapper img': {
        borderRadius: 'md',
      },
    }),
  },
};

export default extendTheme(theme);
