import React from 'react';
import { Helmet } from 'react-helmet';

const DnsPrefetch = ({ url }) => (
  <Helmet>
    <link rel="dns-prefetch" href={url} />
  </Helmet>
);

export default DnsPrefetch;
