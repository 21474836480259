import axios from 'redaxios';
import base64 from 'base-64';

const apiHost = process.env.API_HOST; // do not destructure this
const apiUser = process.env.API_USER; // do not destructure this
const apiPass = process.env.API_PASS; // do not destructure this

axios.defaults.baseURL = `//${apiHost}`;
axios.defaults.withCredentials = false;
axios.defaults.headers = {
  authorization: `Basic ${base64.encode(`${apiUser}:${apiPass}`)}`,
};
