import React from 'react';
import { IconContext } from 'react-icons';
import { QueryParamProvider } from 'use-query-params';
import { Location, globalHistory } from '@reach/router';
import { CookiesProvider } from 'react-cookie';
import { TrackingContextComponent } from './tracking';

const AppProviders = ({ children }) => (
  <IconContext.Provider
    value={{
      style: { display: 'inline-block' },
      attr: { 'aria-hidden': 'true' },
    }}
  >
    <CookiesProvider>
      <TrackingContextComponent>
        <Location>
          {({ location }) => (
            <QueryParamProvider
              location={location}
              reachHistory={globalHistory}
            >
              {children}
            </QueryParamProvider>
          )}
        </Location>
      </TrackingContextComponent>
    </CookiesProvider>
  </IconContext.Provider>
);

export default AppProviders;
