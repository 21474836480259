import React from 'react';
import { Helmet } from 'react-helmet';

const Preconnect = ({ url }) => (
  <Helmet>
    <link rel="preconnect" href={url} />
  </Helmet>
);

export default Preconnect;
