import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';

import theme from '../theme';
import AppProviders from '../../contexts';

const RootComponent = ({ children }) => (
  <ChakraProvider theme={theme} resetCSS portalZIndex={40}>
    <AppProviders>{children}</AppProviders>
  </ChakraProvider>
);

export default RootComponent;
