import React from 'react';
import { Helmet } from 'react-helmet';
import { useToken } from '@chakra-ui/react';
import useSiteMetadata from 'timelesstime-gatsby-utils/hooks/useSiteMetadata';

import DnsPrefech from '../links/dnsPrefetch';
import Preconnect from '../links/preconnect';
import Preload from '../links/preload';

import breeSerifWoff from '../../fonts/bree-serif-v10-latin-regular.woff';
import breeSerifWoff2 from '../../fonts/bree-serif-v10-latin-regular.woff2';
import latoWoff from '../../fonts/lato-v17-latin-regular.woff';
import latoWoff2 from '../../fonts/lato-v17-latin-regular.woff2';
import montserratWoff from '../../fonts/montserrat-v15-latin-regular.woff';
import montserratWoff2 from '../../fonts/montserrat-v15-latin-regular.woff2';

const preconnectConfig = [
  {
    url: 'https://fonts.googleapis.com',
    dnsPrefetch: true,
    preconnect: true,
  },
  { url: 'https://fonts.gstatic.com', dnsPrefetch: true, preconnect: true },
  {
    url: 'https://images.ctfassets.net',
    dnsPrefetch: true,
    preconnect: true,
  },
  {
    url: 'https://i.ytimg.com/',
    dnsPrefetch: true,
    preconnect: false,
  },
];
const dnsPrefetchUrls = preconnectConfig.filter(
  ({ dnsPrefetch }) => dnsPrefetch === true,
);
const preconnectUrls = preconnectConfig.filter(
  ({ dnsPrefetch }) => dnsPrefetch === true,
);

const RootLayout = ({ children }) => {
  const lightBlue = useToken('colors', 'tt.lightBlue');
  const gray900 = useToken('colors', 'gray.900');
  const { siteUrl } = useSiteMetadata();
  // only preload woff2 URLs since browsers that support preload support woff2
  const preloadUrls = [
    { url: `${siteUrl}${breeSerifWoff2}`, as: 'font', type: 'font/woff2' },
    { url: `${siteUrl}${latoWoff2}`, as: 'font', type: 'font/woff2' },
    { url: `${siteUrl}${montserratWoff2}`, as: 'font', type: 'font/woff2' },
  ];
  return (
    <>
      {dnsPrefetchUrls.map(({ url }) => (
        <DnsPrefech key={url} url={url} />
      ))}
      {preconnectUrls.map(({ url }) => (
        <Preconnect key={url} url={url} />
      ))}
      {preloadUrls.map(({ url, as, type }) => (
        <Preload
          key={url}
          url={url}
          as={as}
          type={type}
          crossOrigin="anonymous"
        />
      ))}
      {/* fonts from https://google-webfonts-helper.herokuapp.com/fonts */}
      <Helmet>
        <style type="text/css">{`
/* bree-serif-regular - latin  1234 */
@font-face {
  font-family: 'Bree Serif';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('${breeSerifWoff2}') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${breeSerifWoff}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
      /* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('${latoWoff2}') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${latoWoff}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('${montserratWoff2}') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${montserratWoff}') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
      `}</style>
        <style type="text/css">
          {`
::-moz-selection {
  background: ${lightBlue};
  color: ${gray900};
}
::selection {
  background: ${lightBlue};
  color: ${gray900};
}
      `}
        </style>
      </Helmet>
      {children}
    </>
  );
};

export default RootLayout;
