import React from 'react';
import { Helmet } from 'react-helmet';

const Preload = ({ url, as, type, ...props }) => (
  <Helmet>
    <link rel="preload" href={url} as={as} type={type} {...props} />
  </Helmet>
);

export default Preload;
