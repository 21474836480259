/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import RootComponent from 'timelesstime-ui/components/root-component';
import RootLayout from 'timelesstime-ui/components/root-layout';
import 'timelesstime-ui/utils/axios-globals';

export const wrapPageElement = ({ element }) => (
  <RootLayout>{element}</RootLayout>
);

export const wrapRootElement = ({ element }) => (
  <RootComponent>{element}</RootComponent>
);
