import React, { createContext, useState } from 'react';
import ReactGA from 'react-ga';
import addDays from 'date-fns/addDays';
import store from 'store/dist/store.modern';
import storeExpiry from 'store/plugins/expire';
import * as Sentry from '@sentry/browser';

store.addPlugin(storeExpiry);

const USER_EMAIL_STORE_KEY = 'user-email';

const GACategory = 'user-tracking';

const log = (msg) => {
  console.info(msg); // eslint-disable-line no-console
};

const associate = (
  { email /* firstName, lastName, customDetails */ },
  setTrackingState,
  trackingState,
) => {
  const storeExpires = addDays(new Date(), 28).getTime();
  ReactGA.event(GACategory, 'user-associated');
  Sentry.configureScope((scope) => {
    scope.setUser({ email });
  });
  log(`${email} is now associated`);
  store.set(USER_EMAIL_STORE_KEY, email, storeExpires);
  setTrackingState({
    ...trackingState,
    isAnonymous: false,
  });
};

const Context = createContext();

const TrackingContextComponent = ({ children }) => {
  const storedUserEmail = store.get(USER_EMAIL_STORE_KEY);
  const isAnonymous =
    typeof window === 'undefined' ||
    typeof storedUserEmail === 'undefined' ||
    storedUserEmail.length < 3;
  const [trackingState, setTrackingState] = useState({
    isAnonymous,
    associate: (email, firstName, lastName, customDetails) =>
      associate(
        {
          email,
          firstName,
          lastName,
          customDetails,
        },
        setTrackingState,
        trackingState,
      ),
  });
  return <Context.Provider value={trackingState}>{children}</Context.Provider>;
};

export { Context as default, TrackingContextComponent };
